<template>
  <div class="rs-field__wrapper" :class="wrapperClass">
    <div v-bind="$attrs" class="rs-field" @click.prevent="focusInput">
      <div v-if="label" class="rs-field__label">
        {{ label }}
      </div>
      <div class="flex items-center justify-center">
        <div v-if="$slots.leftIcon" class="rs-field__left-icon">
          <slot name="leftIcon" />
        </div>
        <div ref="rsInputRef" class="rs-field__input">
          <slot />
        </div>
        <div v-if="$slots.rightIcon" class="rs-field__right-icon">
          <slot name="rightIcon" />
        </div>
      </div>
    </div>
    <div v-if="$slots.error" class="rs-field__error" :class="errorClass">
      <slot name="error" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  label: String,
  icon: String,
  wrapperClass: String,
  errorClass: String,
})

const rsInputRef = ref(null)

const focusInput = () => {
  const field = rsInputRef.value.querySelector('.input')
  if (field) {
    field.focus()
  }
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<style scoped>
.rs-field__wrapper {
  @apply relative;
}
.rs-field {
 @apply relative rounded border border-gray-200 p-4;
}
.rs-field:not(.disabled) {
  @apply cursor-pointer;
}
.rs-field.disabled {
  @apply cursor-not-allowed bg-gray-100;
}
.rs-field.error {
 @apply border border-red-500;
}
.rs-field__label {
  @apply absolute text-xs -top-3 bg-white px-1;
}
.rs-field.disabled > .rs-field__label{
  @apply !bg-transparent;
}
.rs-field__input {
  @apply flex-1 overflow-x-hidden;
  min-height: 1rem;
}
.rs-field__left-icon {
  @apply mr-4;
}
.rs-field__right-icon {
  @apply ml-4;
}
.rs-field__error {
  @apply absolute top-full left-0 mt-1 text-red-500 text-xs;
}
@screen sm {
  .rs-field__label {
    @apply text-base;
  }
}
</style>
